<template>
  <!-- 作品 -->
  <div class="kj_idea">
    <div
      class="current-nav centerText"
      v-if="Object.keys(gdData).length != 0"
      :style="{
        background: `url(${gdData.guandianImg}) no-repeat`
      }"
    ></div>
    <div class="all_no_data" v-else>
      <i class="el-icon-loading"></i>
      <p>数据正在加载</p>
    </div>
    <div class="kj_idea_nav" v-if="!currentId">
      <div class="news_type">
        <h2>IDEA</h2>
        <ul>
          <li
            v-for="(item, idx) in typeList"
            :key="idx"
            @click="active = item.id"
          >
            <a
              :href="'#/kj_idea?currentIdx=' + item.id"
              :style="{ color: active == item.id ? '#5f6061' : '' }"
              @click="switchData(item, idx)"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
      <ul class="news_list" v-if="tablList.length != 0">
        <li class="list_li" v-for="(item, idx) in tablList" :key="idx">
          <a
            :href="'#/kj_idea?currentIdx=' + active + '&currentId=' + item.id"
            class="li_img zz_all_img"
          >
            <img
              :src="item.cover_image"
              alt=""
              style="width:100%;height:100%"
            />
          </a>
          <div class="li_nav">
            <a :href="'#/kj_idea?currentIdx=' + active + '&currentId=' + item.id">{{ item.create_time }}</a>
            <a :href="'#/kj_idea?currentIdx=' + active + '&currentId=' + item.id">{{ item.title }}</a>
            <p class="two_ellipsis">{{ item.miaoshu }}</p>
            <a :href="'#/kj_idea?currentIdx=' + active + '&currentId=' + item.id" class="li_nav_yd">Read the article</a>
          </div>
        </li>
        <div class="all-pagination">
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChange"
            :current-page.sync="tabPag.page"
            background
            :page-size="6"
            layout="prev, pager, next,total"
            :total="tabPag.total"
            v-if="tabPag.total > 5"
          >
          </el-pagination>
          <span class="no_meet_conditions" v-else>暂无更多数据</span>
        </div>
      </ul>
      <div class="news_list centerText" v-else>
        暂无数据
      </div>
    </div>
    <div class="kj_idea_nav2" v-else>
      <div class="nav_container" v-if="gdxqData.maincontent">
        <div class="main_content" v-html="gdxqData.maincontent"></div>
        <a :href="'#/kj_idea?currentIdx=' + active" style="width: 100%;text-align: center;display: block;">
          <i class="el-icon-close" style="font-size: 30px;"></i>
        </a>
      </div>
      <div class="container_nodata" v-else>暂无数据</div>
    </div>
  </div>
</template>
<script>
import { getPageMsg, getXwTypeData, getAllXwData, getXwxqData } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      gdData: {},
      project_id: '',
      typeList: [],
      active: '',
      tabPag: {
        page: 1,
        total: 0
      },
      tablList: [],
      currentId: '',
      gdxqData: {}
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.active = this.$route.query.currentIdx
          this.getData()
        } else {
          this.active = ''
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.getData()
        } else {
          this.currentId = ''
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      this.project_id = sessionStorage.getItem(this.changeData() + 'project')
      if (this.$route.query.currentIdx) {
        this.active = this.$route.query.currentIdx
      }
      if (this.$route.query.currentId) {
        this.currentId = this.$route.query.currentId
        this.toDetails(this.currentId)
      } else {
        this.currentId = ''
      }
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.gdData = res.data.data.guandian
        }
      })
      getXwTypeData(1, this.project_id).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部',
            id: ''
          })
          this.typeList = res.data.data
        }
      })
      this.allxwData(this.tabPag.page, this.active, this.project_id)
    },
    allxwData (i, x, d) {
      getAllXwData(i, x, d).then(res => {
        if (res.data.code == 1) {
          this.tablList = res.data.data.list
          this.tabPag.total = res.data.data.total
        }
      })
    },
    switchData (e, i) {
      this.active = e.id
      this.currentId = e.id
      this.allxwData(this.tabPag.page, e.id, this.project_id)
    },
    toDetails (i) {
      this.currentId = i
      getXwxqData(i).then(res => {
        if (res.data.code == 1) {
          this.gdxqData = res.data.data
        }
      })
    },
    handleCurrentChange (val) {
      this.allxwData(val, '', this.project_id)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.kj_idea {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  .current-nav {
    background-size: 100% 100% !important;
  }
  .no_data {
    height: 400px;
  }
  .kj_idea_nav,
  .kj_idea_nav2 {
    line-height: 1.3;
    width: 80%;
    margin: 50px auto;
    color: #5f6061;
    .news_type {
      margin-bottom: 20px;
      display: flex;
      h2 {
        font-size: 30px;
        width: 15%;
      }
      ul {
        display: flex;
        align-items: center;
        li {
          border-left: 3px solid #898989;
          box-sizing: border-box;
          padding: 0 30px;
          color: #898989;
        }
        li:nth-child(1) {
          border: none;
        }
        li:hover a {
          color: #5f6061;
        }
      }
    }
    .news_list {
      min-height: 300px;
      .list_li {
        padding: 30px 0;
        border-bottom: 1px solid #eee;
        display: flex;
        justify-content: space-between;
        .li_img {
          width: 300px;
        }
        .li_nav {
          width: 49%;
          margin-right: 130px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          a {
            margin-bottom: 20px;
            color: #0f0b09;
          }
          p {
            font-size: 12px;
            line-height: 2;
          }
          .li_nav_yd {
            margin: 20px 0 0 0;
            text-decoration: underline;
          }
        }
      }
    }
    .nav_container {
      .main_content img {
        max-width: 86%;
      }
    }
    .container_nodata {
      height: 500px;
      line-height: 20;
      text-align: center;
    }
  }
}
</style>
